@import "../../pages/variables.scss";

.yields {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .yield {
    margin: 0;
    margin: 0.5 auto;
    width: 99%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    align-items: center;
    
        > div {
            width: 100%;
        }
    }
}

.headers {
    display: grid;
    padding: 10px 10px 5px 10px;
    grid-template-columns: 50% 45% 5%;
    grid-template-areas: ". values .";

    @media (min-width: $desktop-4k-width) {
        grid-template-columns: 45% 30% 25%;
    }
    > div {
        display: flex;
        justify-content: space-between;
        grid-area: values;
        
        > div {
            display: flex;
            justify-content: center;
        }
    }
}

.yield {
   > div {
    display: flex;
    align-items: center;

    

    .country, .values {
        display: flex;
        align-items: center;
        width: 50%;

        @media (min-width: $desktop-4k-width) {
            width: 45%;
        }
    }

    .country {
        p {
            line-height: initial;
        }
    }

    .values {
        justify-content: space-between;
        width: 45%;

        @media (min-width: $desktop-4k-width) {
            width: 30%;
        }

        > div {
            text-align: left;
            width: 35px;
        }

        p {
            font-weight: 600;
        }
    }
   }
}

.yieldCardContent {
    padding: 0px 10px !important;

    @media screen and (min-width: $tablet-standard-width) {
        padding: 0px 10px !important;

    }

    @media screen and (min-width: $laptop-standard-width) {
        padding: 0px 10px !important;

    }

    @media screen and (min-width: $desktop-standard-width) {
        padding: 5px 10px !important;
    }

    @media screen and (min-width: $desktop-4k-width) {
        padding: 25px 25px !important;
    }
}