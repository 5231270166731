@import "../../pages/variables.scss";

.MarketsOverview {
    height: calc(100% - 5px);
    width: calc(100% - 5px);

    .content {
        flex: 1;
        height: calc(100% - 5px);
        width: calc(100% - 5px);
        display: grid;
        grid-template-columns: 100%;
        gap: 5px;

        @media screen and (min-width: $tablet-large-width) {
            width: calc(100% - 15px);
            grid-template-rows: 50% 50%;
            grid-template-columns: 30% 20% 16.66% 16.66% 16.66%;
        }

        > div {
            height: 550px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            &:last-child {
                height: 300px;
            }

            @media screen and (min-width: $tablet-large-width) {
                height: 100% !important;

                &:nth-child(1) {
                    grid-row: span 2;
                }

                &:nth-child(2) {
                    grid-column: span 4;
                }
            }
        }

        .custom-div{
            height:fit-content;
            @media screen and (min-width: $tablet-large-width) {
                height: 100% !important;
            }
        }
    }
}
