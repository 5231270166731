@import "../../pages/variables.scss";

.SectorMoves {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .chart {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3 {
            margin-top: 5px;
        }
        > div {
            flex: 1;
            height: 0;
            width: 100%;
            > div[data-highcharts-chart] {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    .parent { 
        display: grid; 
        grid-template-columns: repeat(1, 1fr); 
        grid-template-rows: repeat(1, 1fr); 
        grid-column-gap: 5px;
        grid-row-gap: 0px; 
        }
        .div1 { grid-area: 1 / 1 / 2 / 3; } 
        .div2 { grid-area: 1 / 4 / 2 / 6; } 
    

        .sectorMovesLabelFonts {    
            font-size: 10px;

            @media screen and (min-width: $tablet-standard-width) {
                font-size: 10px;

            }

            @media screen and (min-width: $laptop-standard-width) {
                font-size: 10px;

            }

            @media screen and (min-width: $desktop-standard-width) {
                font-size: 10px;
            }

            @media screen and (min-width: $desktop-4k-width) {
                font-size: 20px;
            }
        }

        .sectorMovesChipFonts {    
            font-size: 10px;

            @media screen and (min-width: $tablet-standard-width) {
                font-size: 10px;
            }

            @media screen and (min-width: $laptop-standard-width) {
                font-size: 10px;

            }

            @media screen and (min-width: $desktop-standard-width) {
                font-size: 12px;
            }

            @media screen and (min-width: $desktop-4k-width) {
                font-size: 22px;
            }
        }

        
}

