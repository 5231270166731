@import "../../pages/variables.scss";

.charts {
    position: relative;
    height: 100%;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    > div {
        width: calc(100%);
        height: calc(100% / 4);
    }
}






