@import "../../../pages/variables.scss";

.Logo {
    > span {
        height: 25px !important;
        position: static !important;
        img {
            width: unset !important;
            height: 100% !important;
            margin: unset !important;
            position: static !important;
            max-width: 60px !important;
            min-width: unset !important;
            min-height: unset !important;
            max-height: unset !important;
        }

        @media screen and (min-width: $desktop-medium-width) {
            height: 35px !important;

            img {
                max-width: 80px !important;
            }
        }
    }
}
