$mobile-small-width: 480px;
$mobile-medium-width: 640px;
$mobile-large-width: 768px;
$tablet-small-width: 900px;
$tablet-medium-width: 1024px;
$tablet-large-width: 1280px;
$desktop-small-width: 1366px;
$desktop-medium-width: 1440px;
$desktop-large-width: 1920px;
$desktop-extra-large-width: 2560px;
$red: #ef4438;
$red-darker: #c6252e;
$brown: #ab856d;
$ortex-color: #31aba6;
$ortex-color-lighter: #4ac4bf;
$ortex-color-darker: #337f79;
$white: #ffffff;
$white-lighter: #f3f3f4;
$white-medium: #e7e7e8;
$white-darker: #d1d2d4;
$grey-lighter: #939598;
$grey: #808080;
$grey-medium: #58585a;
$grey-darker: #3f3e40;
$black-lighter: #182933;
$black-medium: #0f181b;
$black-darker: #0e1214;
$black: #000000;
$sans-serif: "Roboto", Calibri, sans-serif;
$monospace: "Roboto Mono", monospace;
$scrollbar-size: 12px;
$header-height: 60px;
$header-height-mobile: 40px;
$menu-small-width: 72px;
$menu-large-width: 180px;

$tablet-standard-width: 768px;
$laptop-standard-width: 1280px;
$desktop-standard-width: 1920px;
$desktop-4k-width: 3840px;

$stock-navigation-tabs: 0px;
$stock-header-content-layout: 24px;
$stock-header: 116px;
$stock-header-mobile: 89px;

$ticker-carousel: 25px;
$ticker-carousel-4k: 40px;
$sidemenu_width: 10px;

// selector {
//     ...                                                                                                   // Mobile vertical
//     @media screen and (min-width: $mobile-small-width) { ... }                                            // Mobile horizontal
//     @media screen and (min-width: $mobile-medium-width) and (min-height: $mobile-medium-width) { ... }    // Tablet (iPad) vertical
//     @media screen and (min-width: $tablet-small-width) { ... }                                            // Tablet (iPad) horizontal
//     @media screen and (min-width: $tablet-small-width) and (min-height: $tablet-small-width) { ... }      // Tablet (iPad Pro) vertical
//     @media screen and (min-width: $tablet-large-width) { ... }                                            // Small Desktop
//     @media screen and (min-width: $tablet-large-width) and (min-height: $tablet-small-width) { ... }      // Tablet (iPad Pro) horizontal
//     @media screen and (min-width: $desktop-medium-width) { ... }                                          // Medium Desktop
//     @media screen and (min-width: $desktop-medium-width) and (min-height: $tablet-medium-width) { ... }   // Large Desktop
//     @media screen and (min-width: $desktop-large-width) and (min-height: $desktop-small-width) { ... }    // Extra large Desktop
// }
