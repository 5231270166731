@import "../../pages/variables.scss";

.NewNewsList {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .container {
        height: 550px;
        width: 100%;
        margin: 0 auto;
        overflow: auto;
        @media screen and (min-width: $laptop-standard-width) {
            height: 100%;
        }

        .on-fire {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                margin: auto;
            }
        }

        .card-content {
            min-width: 90%;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 8px !important;

            .card-header {
                display: flex;
                justify-content: space-between;

                .header-content {
                    font-size: 14px;
                    font-weight: 600;
                    color: $grey-darker;
                }

                .source-stock {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0;

                    @media screen and (max-width: $tablet-standard-width) {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-end;
                    }

                    .stocks {
                        display: flex;
                        gap: 10px;

                        > p {
                            margin-left: 2px;
                            margin-right: 2px;
                        }

                        @media screen and (min-width: $mobile-large-width) {
                            gap: 0;
                            margin: 0;
                        }
                    }
                }

                .rating {
                    display: flex;
                    justify-content: flex-end;
                    margin-left:6px;
                }
            }

        }
    }
}

.noNewsText {
    font-size: 10px;

    @media screen and (min-width: $tablet-standard-width) {
        font-size: 10px;
    }

    @media screen and (min-width: $laptop-standard-width) {
        font-size: 12px;
    }

    @media screen and (min-width: $desktop-standard-width) {
        font-size: 16px;
    }

    @media screen and (min-width: $desktop-4k-width) {
        font-size: 24px;
    }
}
