@import "../../pages/variables.scss";

.MorningNote {
    height: 100%;
    font-size: 12px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $desktop-medium-width) {
        font-size: 12px;
    }
    @media screen and (min-width: $desktop-medium-width) and (min-height: $tablet-medium-width) {
        font-size: 16px;
    }
    .input {
        width: 190px;
        padding: 3px;
        font-size: 14px;
        cursor: pointer;
        border-radius: 5px;
        background-color: transparent;
    }
    .checkbox {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
    }
    .editor {
        flex: 1;
        height: 0;
        overflow: auto;
        h1,
        h2,
        h1,
        h2 {
            margin-bottom: 10px;
        }
        p {
            line-height: 20px;
        }
        h1 {
            font-size: 16px;
            @media screen and (min-width: $desktop-medium-width) and (min-height: $tablet-medium-width) {
                font-size: 18px;
            }
        }
        h2 {
            font-size: 14px;
            padding-top: 15px;
            @media screen and (min-width: $desktop-medium-width) {
                font-size: 15px;
            }
            @media screen and (min-width: $desktop-medium-width) and (min-height: $tablet-medium-width) {
                font-size: 17px;
            }
        }
        a {
            color: $ortex-color;
        }
        figure {
            height: 0;
            margin: 0;
            position: relative;
            padding-bottom: 56.25%;
            iframe {
                width: 100%;
                height: 100%;
                border: none;
                position: absolute;
            }
        }
    }

    .note-container-daily {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        /* .reports {
            height: 38%;
        } */
        a {
            color: $ortex-color;
        }
    }

    .note-container-monthly {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
            height: 100%;
            a {
                color: $ortex-color;
            }
        }
    }
}

.data-text {
    font-size: 12px !important;
    max-width: 95%;
    font-family: inherit;
    @media screen and (min-width: $desktop-medium-width) and (min-height: $tablet-medium-width) {
        font-size: 16px !important;
    }
}
