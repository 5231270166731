@import "../../pages/variables.scss";

.tabs {
    height: fit-content;
    @media screen and (min-width: $tablet-standard-width) {
        height: 100%;
    }
    @media screen and (min-width: $desktop-4k-width) {
        padding-bottom: 20px;
    }
}
